<template>
    <div class="nodata-container">
        <div class="empty-result-icon" />
        <div class="empty-message" v-html="getMsg()"></div>
    </div>
</template>

<script>
export default {
    name: 'NoData',
    props: {
        emptyMessage: {
            type: String,
            default: ''
        }
    },
    methods: {
        getMsg: function () {
            if (this.emptyMessage) return this.emptyMessage
            else
                return this.$isMobile()
                    ? '查無任何結果<br/>請點選右上角篩選圖示<br/>開始輸入條件'
                    : '查無任何結果<br/>試試看其他條件'
        }
    }
}
</script>

<style lang="scss" scoped>
.nodata-container {
    display: block;
    justify-content: center;
    width: 100%;
    margin: 100px 0;

    -webkit-animation: fadeInDown 500ms ease-in-out; /* Chrome, Safari, Opera */
    animation: fadeInDown 500ms ease-in-out;

    @keyframes fadeInDown {
        0% {
            opacity: 0;
            -webkit-transform: translateY(40px);
        }
        100% {
            opacity: 1;
            -webkit-transform: translateY(0);
        }
    }
}

.empty-result-icon {
    width: 70px;
    height: 70px;
    margin: auto;
}

.empty-message {
    font-size: 15px;
    color: $secondary-grey;
    text-align: center;
    margin-top: 20px;
}
</style>
